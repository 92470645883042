import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { collection, getFirestore } from 'firebase/firestore';

export const doSignInWithEmailAndPassword = (email, password) =>
	signInWithEmailAndPassword(getAuth(), email, password);

export const doSignOut = () => getAuth().signOut();

export const currentUserId = () => getAuth().currentUser.uid;

export const applications = () => collection(getFirestore(), 'applications');

export const traineeActions = () => collection(getFirestore(), '_traineeActions');

export const ratings = () => collection(getFirestore(), 'ratings');
