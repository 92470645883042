import { UserContext } from '@context';
import Home from '@screens/Home';
import LogIn from '@screens/LogIn';
import { getApp } from 'firebase/app';
import { getAuth, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import 'react-native-gesture-handler';

export default function MainApp() {
	const [initializing, setInitializing] = useState(true);
	const [currentUser, setCurrentUser] = useState(null);

	// Handle user state changes
	function onAuthStateChanged(user: User) {
		setCurrentUser(user);
		if (initializing) setTimeout(() => setInitializing(false), 100);
	}

	useEffect(() => {
		const subscriber = getAuth(getApp()).onAuthStateChanged(onAuthStateChanged);
		return subscriber; // unsubscribe on unmount
	}, [currentUser]);

	if (initializing) return <View />;
	return (
		<UserContext.Provider value={currentUser}>
			{!currentUser && <LogIn />}
			{currentUser && <Home />}
		</UserContext.Provider>
	);
}
