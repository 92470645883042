import { traineeActionSkippedByAdmin } from '@api';
import AdminActions from '@components/AdminActions';
import { admins, containerWidth, width, adminLoxClubProfiles } from '@constants';
import { capitalize, getBirthday, getInterestedIn } from '@utils';
import { ClubIdentifierToHumanReadable } from '../types';
import * as WebBrowser from 'expo-web-browser';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { Image, Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

function ReviewUser({ user }) {
	const {
		createdAt,
		firstName,
		lastName,
		birthday,
		companyName,
		currentLocation,
		education,
		email,
		gender,
		genderIdentity,
		hometown,
		instagram,
		interestedIn,
		personalHistory,
		profession,
		whyJoin,
		referredBy,
		giftCode,
		applicationPhoto,
		isJewish,
		linkedin,
		referred,
		interestingApplication,
		metaData,
	} = user;

	const openInstagram = (handle) => {
		WebBrowser.openBrowserAsync(`https://instagram.com/${handle}`);
	};

	const openLinkedin = () => {
		WebBrowser.openBrowserAsync(generateLinkedinLink(linkedin));
	};
	const openApplication = (id) =>
		WebBrowser.openBrowserAsync(`https://pickles.loxclubapp.com/user/${id}/application`);

	const skip = async () => {
		try {
			await traineeActionSkippedByAdmin(user);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<View style={styles.container}>
			<View style={styles.left}>
				<Image source={applicationPhoto} style={styles.image} resizeMode="contain" />
			</View>
			<View style={styles.right}>
				<AdminActions activeApp={user} metaData={metaData} />
				<Text
					style={[styles.name, styles.link]}
					onPress={() => openApplication(user.metaData.affectedUserId)}
				>{`${firstName} ${lastName}`}</Text>
				<InfoItem
					title="Applied on"
					info={`${DateTime.fromMillis(
						(createdAt.seconds || createdAt._seconds) * 1000
					).toLocaleString(DateTime.DATETIME_FULL)} ${giftCode ? ' with a gift code' : ''}`}
				/>
				<InfoItem title="Age" info={getBirthday(birthday)} />
				<InfoItem title="Email" info={email} />
				<InfoItem
					title="Instagram"
					info={`@${instagram}`}
					link
					onPress={() => openInstagram(instagram)}
				/>
				{!!linkedin && (
					<InfoItem title="LinkedIn" info={getLinkedinName(linkedin)} link onPress={openLinkedin} />
				)}
				{interestingApplication && <InfoItem title="Interesting Application" info={'Yes'} />}
				<Divider />
				<InfoItem title="Gender" info={genderIdentity ? genderIdentity : capitalize(gender)} />
				<InfoItem title="Interested In" info={getInterestedIn(interestedIn)} />
				<Divider />
				<InfoItem title="Lives" info={currentLocation.name} />
				<InfoItem title="From" info={hometown?.name || '<Not Collected>'} />
				{isJewish !== undefined && (
					<Text style={styles.infoTitle}>{isJewish ? 'Jewish' : 'Gentile'} </Text>
				)}

				<Divider />
				<InfoItem title="Studied at" info={education} />
				<InfoItem
					title="Works as"
					info={`${profession}${companyName ? ` at ${companyName}` : ''}`}
				/>
				<Divider />
				{referredBy.length > 0 && (
					<View>
						<Text style={styles.infoTitle}>Referred By</Text>
						{referredBy.map((r) => (
							<Referral key={r.id} referral={r} openApplication={openApplication} />
						))}
						<Divider />
					</View>
				)}
				{referred.length > 0 && (
					<View>
						<Text style={styles.infoTitle}>Referred</Text>
						{referred.map((r) => (
							<Referral key={r.id} referral={r} openApplication={openApplication} />
						))}
						<Divider />
					</View>
				)}

				<View style={styles.shortAnswer}>
					<Text style={styles.infoTitleShort}>Career and Ambitions:</Text>
					<Text style={styles.shortAnswerText}>{personalHistory}</Text>
					{Boolean(whyJoin) && (
						<>
							<Text style={styles.infoTitleShort}>
								Why do you want to join the Lox Club Community?
							</Text>
							<Text style={styles.shortAnswerText}>{whyJoin}</Text>
						</>
					)}
					<Divider />
					<ApplicationMeta user={user} />
					<Text onPress={skip} style={[styles.link, styles.info, { marginVertical: 20 }]}>
						Skip
					</Text>
				</View>
			</View>
		</View>
	);
}

function Referral({ referral, openApplication }) {
	//Dont show rating for admins
	const rating = adminLoxClubProfiles.includes(referral.id) ? 10 : referral.rating;
	return (
		<View style={{ marginBottom: 10 }}>
			<Text style={[styles.info, styles.link]} onPress={() => openApplication(referral.id)}>
				{referral.name}
			</Text>
			<Text style={[styles.info, { fontSize: 14 }]}>Status: {referral.status}</Text>
			<Text style={[styles.info, { fontSize: 14 }]}>Rating: {rating}</Text>
		</View>
	);
}

function ApplicationMeta({ user }) {
	const getCol = (fieldName, label) =>
		user[fieldName] && (
			<View key={label}>
				<Text style={styles.info}>
					<Text style={styles.bold}>{label}:</Text> {admins[user[fieldName]]}
				</Text>
			</View>
		);
	return (
		<View>
			<Text style={[styles.infoTitle, { marginBottom: 10 }]}>Application Meta</Text>
			<View>
				<Text style={styles.info}>
					<Text style={styles.bold}>Club:</Text> {ClubIdentifierToHumanReadable[user?.clubId]}
				</Text>
				<Text style={styles.info}>
					<Text style={styles.bold}>Reviewed At:</Text>{' '}
					{Boolean(user?.metaData?.createdAt) &&
						DateTime.fromSeconds(user?.metaData?.createdAt?.seconds).toLocaleString(
							DateTime.DATETIME_FULL
						)}
				</Text>
			</View>
			{!!user.affiliate && (
				<View>
					<Text style={styles.info}>
						<Text style={styles.bold}>Affiliate:</Text> {user.affiliate}
					</Text>
				</View>
			)}
			{[
				['ratedBy', 'Last Rated By'],
				['acceptedBy', 'Accepted By'],
				['waitlistedBy', 'Waitlisted By'],
				['blacklistedBy', 'Blacklisted By'],
				['needsReviewSubmittedBy', 'Needs Review Marked By'],
				['categoryAddedBy', 'Category Assigned By'],
			].map(([fieldName, label]) => getCol(fieldName, label))}
		</View>
	);
}
function InfoItem({ title, info, link = false, onPress = () => {} }) {
	return (
		<View style={styles.infoContainer}>
			<Text style={styles.infoTitle}>{title}: </Text>
			<Text style={[styles.info, link && styles.link]} onPress={onPress}>
				{info}
			</Text>
		</View>
	);
}

function Divider(props) {
	return <View style={styles.divider} />;
}

const styles = EStyleSheet.create({
	container: {
		flex: 1,
		// backgroundColor: 'blue',
		width: containerWidth,
		...(width > 700 && { flexDirection: 'row' }),
		marginTop: 10,
	},
	bold: {
		fontFamily: '$bold',
	},
	left: {
		width: width > 700 ? containerWidth / 2 : containerWidth,
		height: width > 700 ? containerWidth / 2 : containerWidth,
	},
	right: {
		width: width > 700 ? containerWidth / 2 : containerWidth,
		// height: containerWidth / 2,
		paddingLeft: 20,
	},
	image: {
		width: width > 700 ? containerWidth / 2 : containerWidth,
		height: width > 700 ? containerWidth / 2 : containerWidth,
	},
	infoContainer: {
		flexDirection: 'row',
		alignItems: 'baseline',
		marginBottom: 5,
	},
	infoTitle: {
		fontFamily: '$bold',
		fontSize: 18,
		color: '$darkBlue',
	},
	info: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
		flex: 1,
	},
	name: {
		fontFamily: '$bold',
		fontSize: 20,
		marginBottom: 10,
	},
	divider: {
		width: '100%',
		height: 1,
		backgroundColor: '$darkBlue50',
		marginVertical: 10,
	},
	infoTitleShort: {
		fontFamily: '$bold',
		fontSize: 20,
		color: '$darkBlue',
		marginBottom: 10,
	},
	shortAnswerText: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
		marginBottom: 20,
	},
	link: {
		textDecorationLine: 'underline',
	},
});

function generateLinkedinLink(linkedin) {
	if (linkedin.toLowerCase().includes('linkedin.com')) {
		const split = linkedin.split('/');
		const matcher = _.findIndex(split, (a) => a === 'in');
		if (matcher !== -1) return `https://www.linkedin.com/in/${split[matcher + 1]}`;
	}
	return `https://www.linkedin.com/in/${linkedin}`;
}

function getLinkedinName(linkedin) {
	if (linkedin.toLowerCase().includes('linkedin.com')) {
		const split = linkedin.split('/');
		const matcher = _.findIndex(split, (a) => a === 'in');
		if (matcher !== -1) return split[matcher + 1];
	}
	return linkedin;
}

export default ReviewUser;
