import { getAuth } from 'firebase/auth';
import {
	doc,
	getDoc,
	limit,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { applications, traineeActions } from './firebase';

export const getAppsListener = (filterByMinionId: string | null) => {
	if (Boolean(filterByMinionId)) {
		return query(
			traineeActions(),
			where('hasBeenReviewedByAdmin', '==', false),
			where('userId', '==', filterByMinionId),
			orderBy('createdAt', 'asc'),
			limit(1)
		);
	}
	return query(
		traineeActions(),
		where('hasBeenReviewedByAdmin', '==', false),
		orderBy('createdAt', 'asc'),
		limit(2)
	);
};

export const traineeActionSkipped = async (application) => {
	try {
		await updateDoc(doc(traineeActions(), application.id), {
			hasBeenReviewedByAdmin: true,
			hasBeenReviewedByTrainee: true,
			context: {
				decision: 'Skipped',
			},
			denied: true,
			reviewedBy: getAuth().currentUser.uid,
			updatedAt: serverTimestamp(),
		});
	} catch (error) {
		console.log(error);
	}
};

export const traineeActionSkippedByAdmin = async (application) => {
	try {
		await Promise.all([
			updateDoc(doc(traineeActions(), application.id), {
				hasBeenReviewedByAdmin: true,
				context: {
					decision: 'Skipped',
				},
				denied: true,
				reviewedBy: getAuth().currentUser.uid,
				updatedAt: serverTimestamp(),
			}),
			setDoc(doc(traineeActions()), {
				...application.metaData,
				createdAt: serverTimestamp(),
				updatedAt: serverTimestamp(),
			}),
		]);
	} catch (error) {
		console.log(error);
	}
};

export const updateApplication = ({ id, ...data }) => {
	try {
		return updateDoc(doc(applications(), id), {
			...data,
			updatedAt: serverTimestamp(),
		});
	} catch (error) {
		console.log(error);
	}
};

export const getApplicationData = (id) => {
	try {
		return getDoc(doc(applications(), id));
	} catch (error) {
		console.log(error);
	}
};

export const getApplicationPhoto = (uid) => {
	return getDownloadURL(
		ref(getStorage(), `/applications/${uid}/images/optimised/applicationPhoto_640x960.jpg`)
	).catch((e) =>
		getDownloadURL(ref(getStorage(), `/applications/${uid}/images/applicationPhoto.jpg`)).catch(
			(e) => {
				console.log(`failed to get profile image download url ${e}`);
				return null;
			}
		)
	);
};
