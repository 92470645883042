import { admins } from '@constants';
import { currentUserId } from '@api';
import { DateTime } from 'luxon';

export function trimObjectValues(values: any) {
	let trimmed = {};
	for (let [key, value] of Object.entries(values)) {
		if (typeof value == 'string') {
			trimmed[key] = value.trim();
		} else {
			trimmed[key] = value;
		}
	}
	return trimmed;
}

export function test(ms: number) {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			console.log(`Waited ${ms}`);
			resolve();
		}, ms);
	});
}

export const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getInterestedIn = (value) => {
	if (value === 'male') return 'Men';
	if (value === 'female') return 'Women';
	return 'Everyone';
};

export function getBirthday(birthday) {
	return Math.floor(Math.abs(DateTime.fromMillis(birthday * 1000).diffNow('years').years));
}

export function getHeight(cm: number) {
	let inches = Number((cm * 0.393700787).toFixed(0));
	const feet = Math.floor(inches / 12);
	inches %= 12;
	return `${feet}'${inches}`;
}

export function array_move(arr, old_index, new_index) {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr; // for testing
}

export const getName = () => {
	return admins[currentUserId()];
};

export function getEndpointNameForDecision(endpointName) {
	switch (endpointName) {
		case 'pre-accept':
		case 'delay-accept':
			return 'delayAccept';
		case 'accept':
			return 'accept';
		case 'waitlist':
			return 'waitlist';
		case 'blacklist':
			return 'blacklist';
		case 'Waitlist (Permanent)':
			return 'permanentWaitlist';
		case 'Delay Waitlist':
			return 'delayWaitlist';
		case 'Delay Permanent Waitlist':
			return 'delayPermanentlyWaitlist';
		case 'Non-Supported Country':
			return 'Non-Supported Country';
		//Draft Categories
		case 'Draft Accept':
			return 'draftAccept';
		case 'Draft Waitlist':
			return 'draftWaitlist';
		case 'Draft Permanent Waitlist':
			return 'draftPermanentWaitlist';
	}
}
