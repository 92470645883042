import React, { useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import MainButton from '@components/MainButton';
import { TraineeActionName } from '@constants';
import {
	BlacklistReasons,
	PermWaitlistReasons,
	TempWaitlistReasons,
	categoryReasonToHumanReadableMap,
} from '../types';

function RejectionForm({ onPress, visible, activeApp, cancel }) {
	if (!visible) return null;
	const [newRating, setNewRating] = useState<number>();
	const [newDecision, setNewDecision] = useState('');
	const [newWaitlistCategory, setNewWaitlistCategory] = useState<
		TempWaitlistReasons | PermWaitlistReasons | BlacklistReasons
	>();
	const [notesText, setNotesText] = useState('');
	const [welcomeMessageText, setWelcomeMessageText] = useState(activeApp.welcomeMessage || '');
	return (
		<View style={styles.container}>
			<View style={styles.dataContainer}>
				<Text style={styles.title}>Actual Rating:</Text>
				<RNPickerSelect
					style={{ inputWeb: styles.inputWeb }}
					placeholder={{ label: 'Choose a new rating' }}
					onValueChange={(value) =>
						setNewRating(value === 'Choose a new rating' ? undefined : Number(value))
					}
					items={[
						{ label: '1', value: 1 },
						{ label: '2', value: 2 },
						{ label: '3', value: 3 },
						{ label: '4', value: 4 },
						{ label: '5', value: 5 },
						{ label: '6', value: 6 },
						{ label: '7', value: 7 },
						{ label: '8', value: 8 },
						{ label: '9', value: 9 },
						{ label: '10', value: 10 },
					]}
				/>
			</View>

			<View style={styles.dataContainer}>
				<Text style={styles.title}>Actual Decision:</Text>
				<RNPickerSelect
					style={{ inputWeb: styles.inputWeb }}
					placeholder={{ label: 'Choose a new decision' }}
					onValueChange={(value) => setNewDecision(value === 'Choose a new decision' ? '' : value)}
					items={[
						{ label: 'Accept', value: TraineeActionName.ACCEPT },
						{ label: 'Delayed-Accept', value: TraineeActionName.DELAY_ACCEPT },
						{ label: 'Needs Welcome Message', value: 'Needs Welcome Message' },
						{ label: 'Waitlist (Temporary)', value: TraineeActionName.WAITLIST },
						{ label: 'Delayed-Waitlist (Temporary)', value: TraineeActionName.DELAY_WAITLIST },
						{ label: 'Waitlist (Permanent)', value: TraineeActionName.PERMANENTLY_WAITLIST },
						{
							label: 'Delayed-Waitlist (Permament)',
							value: TraineeActionName.DELAY_PERMANENTLY_WAITLIST,
						},
						{ label: 'Blacklist', value: TraineeActionName.BLACKLIST },
						{ label: 'International', value: TraineeActionName.NON_SUPPORTED_CITY },
						{ label: 'Draft Accept', value: TraineeActionName.DRAFT_ACCEPT },
						{ label: 'Draft Waitlist (Temporary)', value: TraineeActionName.DRAFT_WAITLIST },
						{
							label: 'Draft Waitlist (Permanent)',
							value: TraineeActionName.DRAFT_PERMANENTLY_WAITLIST,
						},
					]}
				/>
				{[
					TraineeActionName.WAITLIST,
					TraineeActionName.DELAY_WAITLIST,
					TraineeActionName.DRAFT_WAITLIST,
					TraineeActionName.PERMANENTLY_WAITLIST,
					TraineeActionName.DELAY_PERMANENTLY_WAITLIST,
					TraineeActionName.DRAFT_PERMANENTLY_WAITLIST,
					TraineeActionName.BLACKLIST,
				].includes(newDecision) && (
					<>
						<Text style={[styles.title, { marginTop: 15 }]}>
							{newDecision === TraineeActionName.BLACKLIST ? 'Blacklist' : 'Waitlist'} Category
						</Text>
						<RNPickerSelect
							style={{ inputWeb: styles.inputWeb }}
							placeholder={{ label: 'Choose a category' }}
							onValueChange={(value) =>
								setNewWaitlistCategory(value === 'Choose a category' ? '' : value)
							}
							items={
								[
									TraineeActionName.WAITLIST,
									TraineeActionName.DELAY_WAITLIST,
									TraineeActionName.DRAFT_WAITLIST,
								].includes(newDecision)
									? tempWaitlistReasons
									: newDecision === TraineeActionName.BLACKLIST
									? blacklistReasons
									: permWaitlistReasons
							}
						/>
					</>
				)}
			</View>
			<View style={styles.dataContainer}>
				<Text style={styles.title}>New Welcome Message</Text>
				<TextInput
					value={welcomeMessageText}
					onChangeText={(e) => setWelcomeMessageText(e)}
					multiline
					textAlignVertical="top"
					blurOnSubmit
					style={styles.textInput}
					maxLength={200}
				/>
			</View>
			<View style={styles.dataContainer}>
				<Text style={styles.title}>Notes</Text>
				<TextInput
					value={notesText}
					onChangeText={(e) => setNotesText(e)}
					multiline
					textAlignVertical="top"
					blurOnSubmit
					style={styles.textInput}
				/>
			</View>

			<MainButton
				title="Submit"
				onPress={() =>
					onPress({
						rating: newRating,
						decision: newDecision,
						welcomeMessage: welcomeMessageText,
						notes: notesText,
						categoryReason: newWaitlistCategory,
					})
				}
				buttonStyles={{ marginTop: 0, marginBottom: 10 }}
				disabled={!newDecision && !activeApp.metaData.actionName}
			/>
			<TouchableOpacity onPress={cancel}>
				<Text style={styles.cancelText}>Cancel</Text>
			</TouchableOpacity>
		</View>
	);
}

const styles = EStyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	dataContainer: {
		width: '100%',
		marginBottom: 20,
	},
	title: {
		fontFamily: '$bold',
		fontSize: 16,
		color: '$darkBlue',
		marginRight: 10,
	},
	inputWeb: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
		paddingVertical: 12,
		borderWidth: 1,
		borderColor: 'gray',
		borderRadius: 4,
	},
	textInput: {
		borderColor: '$darkBlue',
		borderWidth: 1,
		height: 100,
		paddingTop: 10,
		paddingLeft: 10,
		color: '$darkBlue',
		fontFamily: '$book',
		fontSize: 15,
		borderRadius: 6,
		width: '100%',
	},
	cancelText: {
		marginVertical: 10,
		fontFamily: '$main',
		fontSize: 14,
		color: '$darkBlue',
	},
});

const tempWaitlistReasons = Object.values(TempWaitlistReasons).map((key) => ({
	label: categoryReasonToHumanReadableMap[key],
	value: key,
}));

const permWaitlistReasons = Object.values(PermWaitlistReasons).map((key) => ({
	label: categoryReasonToHumanReadableMap[key],
	value: key,
}));

const blacklistReasons = Object.values(BlacklistReasons).map((key) => ({
	label: categoryReasonToHumanReadableMap[key],
	value: key,
}));

export default RejectionForm;
