import React, { useState, useEffect } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View, Text } from 'react-native';
import {
	getAppsListener,
	getApplicationData,
	getApplicationPhoto,
	traineeActionSkipped,
	applications,
	ratings,
	getCount,
	getCountForMinion,
} from '@api';
import images from '@images';
import { width } from '@constants';
import Logout from '@components/Logout';
import NoApps from '@components/NoApps';
import ReviewUser from '@components/ReviewUser';
import FilterByMinion from '@components/FilterByMinion';
import { AppsRemainingContext } from '../context';
import { documentId, getDocs, onSnapshot, query, where } from 'firebase/firestore';

const containerWidth = width * 0.9;

function Home() {
	const [loading, setLoading] = useState(true);
	const [activeApp, setActiveApp] = useState(null);
	const [noApps, setNoApps] = useState(false);
	const [appsCount, setAppsCount] = useState(0);
	const [minionAppsCount, setMinionAppsCount] = useState(0);
	const [filterByMinionId, setFilterByMinionId] = useState<string | null>(null);
	useEffect(() => {
		const listener = onSnapshot(getAppsListener(filterByMinionId), async (snapshot) => {
			setLoading(true);

			setNoApps(snapshot.size === 0);
			if (snapshot.size === 0) {
				setLoading(false);
			} else {
				const { affectedUserId, ...rest } = snapshot.docs[0].data();
				const [applicationData, applicationPhoto] = await Promise.all([
					getApplicationData(affectedUserId),
					getApplicationPhoto(affectedUserId),
				]);

				if (applicationData.get('category') !== 'Needs Review') {
					setTimeout(async () => {
						const applicationDataNew = await getApplicationData(affectedUserId);
						if (applicationDataNew.get('category') !== 'Needs Review')
							return traineeActionSkipped(snapshot.docs[0]);
					}, 3000);
				}
				const [referredBy, referred] = await Promise.all([
					getReferredBy(applicationData.data().referredBy),
					getSuccessfulReferrals(affectedUserId),
				]);
				setActiveApp({
					id: snapshot.docs[0].id,
					metaData: {
						affectedUserId,
						...rest,
						...rest.traineeDecision,
					},
					...applicationData.data(),
					applicationPhoto: !applicationPhoto ? images.default : applicationPhoto,
					referredBy,
					referred,
				});
				setLoading(false);
			}
		});

		return listener;
	}, [filterByMinionId]);

	useEffect(() => {
		getCount().then((c) => setAppsCount(c));
	}, []);

	useEffect(() => {
		if (filterByMinionId) {
			getCountForMinion(filterByMinionId).then((c) => setMinionAppsCount(c));
		}
	}, [filterByMinionId]);

	if (loading) return <Text>Loading...</Text>;

	return (
		<AppsRemainingContext.Provider
			value={{
				count: appsCount,
				decrementCount: () => setAppsCount((prevAppsCount) => prevAppsCount - 1),
				minionCount: minionAppsCount,
				decrementMinionCount: () => {
					if (Boolean(filterByMinionId) && minionAppsCount > 0) {
						setMinionAppsCount((prevAppsCount) => prevAppsCount - 1);
					}
				},
			}}
		>
			<View style={styles.container}>
				<Logout />
				<FilterByMinion onChange={setFilterByMinionId} filterByMinionId={filterByMinionId} />
				{noApps ? <NoApps /> : <ReviewUser user={activeApp} />}
			</View>
		</AppsRemainingContext.Provider>
	);
}

async function getReferredBy(referredBy) {
	if (!referredBy || referredBy.length === 0) return [];
	const referred = await getDocs(query(applications(), where(documentId(), 'in', referredBy)));
	const rated = await getDocs(query(ratings(), where(documentId(), 'in', referredBy)));

	const referredFinal = referred.docs.filter((doc) => doc.exists);
	const ratedFinal = rated.docs.filter((doc) => doc.exists);

	return referredFinal.map((t1) => ({
		id: t1.id,
		name: `${t1.get('firstName')} ${t1.get('lastName')}`,
		status: t1.get('category'),
		rating: ratedFinal.find((t2) => t2.id === t1.id)?.get('customRating') || 'Unknown',
	}));
}

async function getSuccessfulReferrals(id) {
	const referred = await getDocs(query(applications(), where('referredBy', 'array-contains', id)));
	if (referred.size === 0) return [];
	const rated = await getDocs(
		query(
			applications(),
			where(
				documentId(),
				'in',
				referred.docs.map((a) => a.id)
			)
		)
	);

	const referredFinal = referred.docs.filter((doc) => doc.exists);
	const ratedFinal = rated.docs.filter((doc) => doc.exists);

	return referredFinal.map((t1) => ({
		id: t1.id,
		name: `${t1.get('firstName')} ${t1.get('lastName')}`,
		status: t1.get('category'),
		rating: ratedFinal.find((t2) => t2.id === t1.id)?.get('customRating') || 'Unknown',
	}));
}

const styles = EStyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingVertical: 20,
		width: containerWidth,
	},
});

export default Home;
