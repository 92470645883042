import { doSignInWithEmailAndPassword } from '@api';
import MainButton from '@components/MainButton';
import { width } from '@constants';
import {
	getAuth,
	getMultiFactorResolver,
	MultiFactorResolver,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	RecaptchaVerifier,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { Text, TextInput, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const TwoFactorVerificationForm = ({
	resolver,
	setErrors,
}: {
	resolver: any;
	setErrors: (errors: string[]) => void;
}) => {
	const [verificationId, setVerificationId] = useState('');
	const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
	const recaptchaElement = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (recaptchaElement.current) {
			setRecaptchaVerifier(
				new RecaptchaVerifier(getAuth(), recaptchaElement.current, {
					size: 'invisible',
				})
			);
		}
	}, []);

	useEffect(() => {
		if (recaptchaVerifier && resolver) {
			const phoneAuthProvider = new PhoneAuthProvider(getAuth());
			var phoneInfoOptions = {
				multiFactorHint: resolver.hints[0],
				session: resolver.session,
			};
			// Send SMS verification code.
			phoneAuthProvider
				.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
				.then((verificationId) => {
					setVerificationId(verificationId);
				})
				.catch((e) => {
					setErrors([e.message]);
				});
		}
	}, [recaptchaVerifier, resolver, setErrors]);

	const handleVerificationCodeSubmit = (verificationCode: string) => {
		if (recaptchaVerifier) {
			var cred = PhoneAuthProvider.credential(verificationId, verificationCode);
			var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
			// Complete sign-in.
			return resolver.resolveSignIn(multiFactorAssertion).catch((e) => {
				setErrors([e.message]);
			});
		}
	};

	const VerificationCodeSubmitForm = () => {
		const [verificationCode, setVerificationCode] = useState('');
		return (
			<View>
				<View>
					<Text style={styles.title}>Verification Code</Text>
					<TextInput
						style={styles.input}
						value={verificationCode}
						onChangeText={(e) => setVerificationCode(e)}
						placeholder=""
					/>
				</View>
				<View>
					<MainButton
						onPress={() => handleVerificationCodeSubmit(verificationCode)}
						title="Verify"
					/>
				</View>
			</View>
		);
	};

	return (
		<View>
			{!verificationId ? (
				<View>
					<View>
						<div ref={recaptchaElement}></div>
					</View>
				</View>
			) : (
				<VerificationCodeSubmitForm />
			)}
		</View>
	);
};

function LogIn(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState<string[]>([]);
	const [twoFactorResolver, setTwoFactorResolver] = useState<MultiFactorResolver | null>(null);
	const [showTwoFactorForm, setShowTwoFactorForm] = useState(false);
	const submit = () => {
		signInWithEmailAndPassword(getAuth(), email, password)
			.then(() => {
				setEmail('');
				setPassword('');
				setErrors([]);
			})
			.catch((error) => {
				if (error.code === 'auth/multi-factor-auth-required') {
					// The user is a multi-factor user. Second factor challenge is required.
					setTwoFactorResolver(getMultiFactorResolver(getAuth(), error));
					setShowTwoFactorForm(true);
				} else {
					setErrors([error.message]);
					setShowTwoFactorForm(false);
					setTwoFactorResolver(null);
				}
			});
	};

	return (
		<View style={styles.container}>
			<Text style={styles.title}>Email</Text>
			<TextInput
				style={styles.input}
				textContentType="emailAddress"
				value={email}
				onChangeText={(e) => {
					setEmail(e);
				}}
			/>

			<Text style={styles.title}>Password</Text>
			<TextInput
				style={styles.input}
				secureTextEntry
				textContentType="password"
				value={password}
				onChangeText={(e) => {
					setPassword(e);
				}}
			/>
			{showTwoFactorForm ? (
				<TwoFactorVerificationForm resolver={twoFactorResolver} setErrors={setErrors} />
			) : (
				<MainButton onPress={submit} title="Sign In" />
			)}
			{!!errors.length && errors.map((error, index) => <p key={index}>{error}</p>)}
		</View>
	);
}

const styles = EStyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	title: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
	},
	input: {
		width: width * 0.8,
		height: 45,
		borderColor: 'lightgrey',
		borderWidth: 1,
		marginBottom: 20,
	},
});

export default LogIn;
