export enum TempWaitlistReasons {
	IDENTITY_UNVERIFIABLE = 'IDENTITY_UNVERIFIABLE',
	LOW_QUALITY_APPLICATION = 'LOW_QUALITY_APPLICATION',
	LOW_QUALITY_CANDIDATE = 'LOW_QUALITY_CANDIDATE',
	SUSPICIOUS_APPLICATION = 'SUSPICIOUS_APPLICATION',
	NO_INSTAGRAM = 'NO_INSTAGRAM',
	OTHER = 'OTHER',
}

export enum PermWaitlistReasons {
	EXTREMELY_LOW_QUALITY_CANDIDATE = 'EXTREMELY_LOW_QUALITY_CANDIDATE',
	EXTREMELY_LOW_QUALITY_APPLICATION = 'EXTREMELY_LOW_QUALITY_APPLICATION',
	CATFISH_APPLICATION = 'CATFISH_APPLICATION',
	INAPPROPRIATE_APPLICATION = 'INAPPROPRIATE_APPLICATION',
	OTHER = 'OTHER',
}

export enum BlacklistReasons {
	OFFENSIVE_APPLICATON = 'OFFENSIVE_APPLICATON',
	OTHER = 'OTHER',
}

export const categoryReasonToHumanReadableMap = {
	[TempWaitlistReasons.IDENTITY_UNVERIFIABLE]: 'Requested Application Edits (Can’t Verify)',
	[TempWaitlistReasons.LOW_QUALITY_CANDIDATE]: 'Maybe later',
	[TempWaitlistReasons.LOW_QUALITY_APPLICATION]:
		'Requested Application Edits (Terrible Career and Ambitions)',
	[TempWaitlistReasons.SUSPICIOUS_APPLICATION]: 'Seems sus…',
	[TempWaitlistReasons.NO_INSTAGRAM]: 'Requested ID (No Instagram)',
	[PermWaitlistReasons.EXTREMELY_LOW_QUALITY_CANDIDATE]: 'Not Lox Club Material',
	[PermWaitlistReasons.EXTREMELY_LOW_QUALITY_APPLICATION]: 'Terrible application - P',
	[PermWaitlistReasons.CATFISH_APPLICATION]: "Where's Nev Shulman!?",
	[PermWaitlistReasons.INAPPROPRIATE_APPLICATION]: 'Inappropriate',
	[BlacklistReasons.OFFENSIVE_APPLICATON]: 'Extremely inappropriate',
	[TempWaitlistReasons.OTHER]: 'Something else',
};

export enum ClubIdentifier {
	LOX_CLUB = 'LOX_CLUB',
	JADE_CLUB = 'JADE_CLUB',
}

export const ClubIdentifierToHumanReadable = {
	[ClubIdentifier.LOX_CLUB]: 'Lox Club',
	[ClubIdentifier.JADE_CLUB]: 'Jade Club',
	undefined: 'Lox Club',
};
