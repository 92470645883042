import axios from 'axios';
import { updateApplication } from './traineeApplications';
import { getEndpointNameForDecision } from '@utils';
import { DateTime } from 'luxon';
import { TraineeActionName } from '@constants';
import Constants from 'expo-constants';
import _ from 'lodash';
import { getAuth } from 'firebase/auth';
import { applications } from './firebase';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
const url = `https://${Constants.manifest.extra.firebaseConfig.projectId}-api.web.app`;

/*

delay accept
accept with or without welcome message
waitlist temp
waitlsit perm
blacklist
intl
interesting apps

*/

async function webApi({ endpointName, data }) {
	const token = await getAuth().currentUser.getIdToken();
	try {
		await axios({
			method: 'POST',
			url: `${url}/${endpointName}`,
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				Authorization: `Bearer ${token}`,
			},
			data,
		});
	} catch (error) {
		console.log('webApi', error);
	}
}

export async function approveDecision({
	decision,
	id,
	categoryReason,
	shouldAskToVerifyInstagram,
}) {
	const endpointName = getEndpointNameForDecision(decision);
	let shouldContinue = true;
	if (['delayAccept', 'accept'].includes(endpointName) && shouldAskToVerifyInstagram) {
		shouldContinue = window.confirm(
			`This user has no instagram. Tap ok to ${
				endpointName === 'delayAccept' ? 'delay accept' : 'accept'
			} and auto-verify them, tap cancel to abort`
		);
		if (!shouldContinue) {
			return false;
		}
	}
	if (endpointName === 'Non-Supported Country') {
		await webApi({
			endpointName: 'tagApplicationWithCategory',
			data: {
				userId: id,
				category: endpointName,
			},
		});
		await updateDoc(doc(applications(), id), { needsReview: false, updatedAt: serverTimestamp() });
		return true;
	}
	if (
		endpointName === 'delayAccept' ||
		endpointName === 'delayWaitlist' ||
		endpointName === 'delayPermanentlyWaitlist'
	) {
		await webApi({
			endpointName,
			data: {
				userId: id,
				runAtMs: DateTime.utc().plus({ days: 4 }).set({ hour: 17 }).toMillis(),
				...(categoryReason && { categoryReason }),
			},
		});
	} else {
		await webApi({
			endpointName,
			data: {
				userId: id,
				...(categoryReason && { categoryReason }),
			},
		});
	}
	if (['delayAccept', 'accept'].includes(endpointName) && shouldAskToVerifyInstagram) {
		await webApi({
			endpointName: 'verifyInstagram',
			data: {
				userId: id,
			},
		});
	}
	return true;
}

export async function rejectDecision({
	application,
	rating,
	decision,
	notes,
	welcomeMessage,
	categoryReason,
}) {
	let appUpdates: {
		welcomeMessage?: string;
		reviewNotes?: string;
	} = {};
	if (application.welcomeMessage !== welcomeMessage && welcomeMessage !== '') {
		appUpdates.welcomeMessage = welcomeMessage;
	}
	if (notes !== '') {
		appUpdates.reviewNotes = notes;
	}

	if (!_.isEmpty(appUpdates)) {
		await updateApplication({
			id: application.metaData.affectedUserId,
			...appUpdates,
		});
	}

	if (rating !== application.metaData.rating) {
		await webApi({
			endpointName: 'setUserCustomRating',
			data: {
				userId: application.metaData.affectedUserId,
				customRating: rating,
			},
		});
	}

	switch (decision) {
		case TraineeActionName.PERMANENTLY_WAITLIST:
			await webApi({
				endpointName: 'permanentWaitlist',
				data: {
					userId: application.metaData.affectedUserId,
					...(categoryReason && { categoryReason }),
				},
			});
			break;

		case TraineeActionName.NON_SUPPORTED_CITY:
			await webApi({
				endpointName: 'tagApplicationWithCategory',
				data: {
					userId: application.metaData.affectedUserId,
					category: decision,
				},
			});
			break;
		case 'Needs Welcome Message':
			await webApi({
				endpointName: 'tagApplicationWithCategory',
				data: {
					userId: application.metaData.affectedUserId,
					category: decision,
				},
			});
			await updateApplication({
				id: application.metaData.affectedUserId,
				welcomeMessage: '',
			});
			break;

		case TraineeActionName.DELAY_ACCEPT:
			if (shouldAbortBecauseOfNoInsta(application.instagram, 'delay accept')) {
				return false;
			}
			await webApi({
				endpointName: 'delayAccept',
				data: {
					userId: application.metaData.affectedUserId,
					runAtMs: DateTime.utc().plus({ days: 4 }).set({ hour: 17 }).toMillis(),
				},
			});
			if (application.instagram === 'none') {
				await webApi({
					endpointName: 'verifyInstagram',
					data: {
						userId: application.metaData.affectedUserId,
					},
				});
			}
			break;
		case TraineeActionName.DELAY_WAITLIST:
			await webApi({
				endpointName: 'delayWaitlist',
				data: {
					userId: application.metaData.affectedUserId,
					runAtMs: DateTime.utc().plus({ days: 4 }).set({ hour: 17 }).toMillis(),
					...(categoryReason && { categoryReason }),
				},
			});
			await updateApplication({
				id: application.metaData.affectedUserId,
				needsReview: false,
			});
			break;
		case TraineeActionName.DELAY_PERMANENTLY_WAITLIST:
			await webApi({
				endpointName: 'delayPermanentlyWaitlist',
				data: {
					userId: application.metaData.affectedUserId,
					runAtMs: DateTime.utc().plus({ days: 4 }).set({ hour: 17 }).toMillis(),
					...(categoryReason && { categoryReason }),
				},
			});
			await updateApplication({
				id: application.metaData.affectedUserId,
				needsReview: false,
			});
			break;
		case TraineeActionName.ACCEPT:
			if (shouldAbortBecauseOfNoInsta(application.instagram, 'accept')) {
				return false;
			}
			await webApi({
				endpointName: 'accept',
				data: {
					userId: application.metaData.affectedUserId,
				},
			});
			if (application.instagram === 'none') {
				await webApi({
					endpointName: 'verifyInstagram',
					data: {
						userId: application.metaData.affectedUserId,
					},
				});
			}
			break;
		case TraineeActionName.WAITLIST:
			await webApi({
				endpointName: 'waitlist',
				data: {
					userId: application.metaData.affectedUserId,
					...(categoryReason && { categoryReason }),
				},
			});
			break;
		case TraineeActionName.BLACKLIST:
			await webApi({
				endpointName: 'blacklist',
				data: {
					userId: application.metaData.affectedUserId,
					...(categoryReason && { categoryReason }),
				},
			});
			break;

		//Draft Cases
		case TraineeActionName.DRAFT_ACCEPT:
			await webApi({
				endpointName: 'draftAccept',
				data: {
					userId: application.metaData.affectedUserId,
				},
			});
			break;
		case TraineeActionName.DRAFT_WAITLIST:
			await webApi({
				endpointName: 'draftWaitlist',
				data: {
					userId: application.metaData.affectedUserId,
					...(categoryReason && { categoryReason }),
				},
			});
			break;
		case TraineeActionName.DRAFT_PERMANENTLY_WAITLIST:
			await webApi({
				endpointName: 'draftPermanentWaitlist',
				data: {
					userId: application.metaData.affectedUserId,
					...(categoryReason && { categoryReason }),
				},
			});
			break;
	}
	return true;
}

function shouldAbortBecauseOfNoInsta(instagram: string, actionName: string) {
	if (instagram !== 'none') {
		return false;
	}
	if (instagram === 'none') {
		const shouldContinue = window.confirm(
			`This user has no instagram. Tap ok to ${actionName} and auto-verify them, tap cancel to abort`
		);
		return !shouldContinue;
	}
}
export async function getCount() {
	const token = await getAuth().currentUser.getIdToken();
	try {
		const response = await fetch(
			`${url}/elastic/${__DEV__ ? 'development' : 'production'}_applications_v1/_search`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					query: {
						bool: {
							must: [
								{
									term: {
										category: 'Needs Review',
									},
								},
							],
						},
					},
					size: 0,
					_source: {
						includes: [],
					},
				}),
			}
		);
		const json = await response.json();
		return json.hits?.total?.value;
	} catch (error) {
		return 0;
	}
}

export async function getCountForMinion(id: string) {
	const token = await getAuth().currentUser.getIdToken();
	try {
		const response = await fetch(
			`${url}/elastic/${__DEV__ ? 'development' : 'production'}_applications_v1/_search`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					query: {
						bool: {
							must: [
								{
									term: {
										category: 'Needs Review',
									},
								},
								{
									term: {
										ratedBy: id,
									},
								},
							],
						},
					},
					size: 0,
					_source: {
						includes: [],
					},
				}),
			}
		);
		const json = await response.json();
		return json.hits?.total?.value;
	} catch (error) {
		return 0;
	}
}
