import React, { useContext } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View, Text, TouchableOpacity } from 'react-native';
import { doSignOut } from '@api';
import { width } from '@constants';
import { AppsRemainingContext } from '../context';

function Logout() {
	const { count } = useContext(AppsRemainingContext);
	return (
		<View style={styles.container}>
			<Text style={styles.count}>
				Apps remaining: <Text style={styles.bold}>{count}</Text>
			</Text>
			<TouchableOpacity onPress={doSignOut} style={styles.buttonContainer}>
				<Text style={styles.text}>Log Out</Text>
			</TouchableOpacity>
		</View>
	);
}

const styles = EStyleSheet.create({
	container: {
		position: 'absolute',
		top: 5,
		width,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingHorizontal: 10,
		marginBottom: 30,
	},
	text: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
		textDecorationLine: 'underline',
	},

	count: {
		fontFamily: '$main',
		fontSize: 16,
	},
	bold: {
		fontFamily: '$bold',
	},
});

export default Logout;
