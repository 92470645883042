import fonts from '@assets/fonts';
import MainApp from '@navigation';
import Constants from 'expo-constants';
import * as Font from 'expo-font';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { StyleSheet, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

EStyleSheet.build({
	$error: '#A51325',
	$errorRed: 'rgb(146, 7, 0)',
	$deliRed: 'rgb(115, 10, 5)',
	$deliGreen: 'rgb(17, 46, 23)',
	$deliCream: 'rgb(249, 243, 222)',
	$bold: 'CircularStd-Bold',
	$main: 'Circular Std',
	$extraBold: 'CircularStd-Bold',
	$semiBold: 'CircularStd-Bold',
	$book: 'CircularStd-Book',
	$blue: '#0a0234',
	$darkBlue: 'rgb(0, 13, 26)',
	$darkBluePlaceholder: 'rgba(0, 13, 26, 0.33)',
	$darkBlueBorder: 'rgba(0, 13, 26, 0.3)',
	$darkBlue80: 'rgba(0, 13, 26, 0.8)',
	$darkBlue70: 'rgba(0, 13, 26, 0.7)',
	$darkBlue60: 'rgba(0, 13, 26, 0.6)',
	$darkBlue50: 'rgba(0, 13, 26, 0.5)',
	$darkBlue40: 'rgba(0, 13, 26, 0.4)',
	$darkBlue30: 'rgba(0, 13, 26, 0.3)',
	$darkBlue20: 'rgba(0, 13, 26, 0.2)',
	$modalBlue: 'rgb(17, 35, 53)',
	$offWhite: 'rgb(255, 252, 243)',
	$white: 'rgb(255, 255, 255)',
	$white20: 'rgba(255, 255, 255, 0.2)',
	$white30: 'rgba(255, 255, 255, 0.3)',
	$white40: 'rgba(255, 255, 255, 0.4)',
	$white50: 'rgba(255, 255, 255, 0.5)',
	$white70: 'rgba(255, 255, 255, 0.7)',
	$white90: 'rgba(255, 255, 255, 0.9)',
	$black: 'rgb(35, 35, 35)',
	$grey: 'rgb(151, 151, 151)',
	$grey40: 'rgba(151, 151, 151,0.4)',
	$paywallGrey: 'rgb(145, 145, 145)',
	$settingsGrey: 'rgb(178,181,185)',
	$lightGrey: 'rgb(134, 134, 134)',
	$disabledGrey: 'rgb(231, 231, 231)',
	$disabledGreyText: 'rgba(186, 186, 186, 0.7)',
	$darkGrey: 'rgb(168, 168, 168)',
	$purple: '#BD10E0',
	$pink: 'rgb(232, 146, 137)',
	$pinkDisabled: 'rgba(232, 146, 137, 0.4)',
});

const appManifest = Constants.manifest.extra;
if (appManifest.useEmulators) {
	initializeApp({
		projectId: 'demo-test-project',
		apiKey: 'AIzaSyBPjpk5c7zkIVkJHwc9NFtHHGL71OoK5LA',
		storageBucket: '',
	});
	connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
	connectAuthEmulator(getAuth(), 'http://localhost:9099');
	connectStorageEmulator(getStorage(), 'localhost', 9199);
} else {
	const firebaseConfig = appManifest.firebaseConfig;
	const app = initializeApp(firebaseConfig);
	window.self['FIREBASE_APPCHECK_DEBUG_TOKEN'] = appManifest.appCheckDebugToken;
	initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(appManifest.reCaptchaSiteKey),
		isTokenAutoRefreshEnabled: true,
	});
}

function loadFonts() {
	return Promise.all(fonts.map((font) => Font.loadAsync(font)));
}

export default function App() {
	const [isReady, setIsReady] = useState(false);
	useEffect(() => {
		async function loadAssets() {
			await loadFonts();
			setIsReady(true);
		}
		loadAssets();
	}, []);

	if (!isReady) return null;
	return (
		<View style={styles.container}>
			<Helmet>
				<link rel="icon" href={require('./favicon.png')} />
			</Helmet>
			<MainApp />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
