import { getNoAppsMessage } from '@constants/index';
import { getName } from '@utils/index';
import _ from 'lodash';
import React from 'react';
import { Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

function NoApps() {
	return (
		<View style={styles.container}>
			<Text style={styles.text}>
				{getNoAppsMessage(getName())}
				{'\n\n'}
			</Text>
			<Text style={styles.text}>There's no more trainee apps to review right now.</Text>
		</View>
	);
}

const styles = EStyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 20,
	},
	text: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
		textAlign: 'center',
	},
});

export default NoApps;
