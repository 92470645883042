import React, { useContext, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View, Text } from 'react-native';
import { approveDecision, rejectDecision } from '@api';
import { admins, containerWidth } from '@constants';
import { capitalize } from '@utils';
import MainButton from '@components/MainButton';
import RejectionForm from '@components/RejectionForm';
import { AppsRemainingContext } from '../context';
import { categoryReasonToHumanReadableMap } from '../types';

function AdminActions({ metaData, activeApp }) {
	const { decrementCount, decrementMinionCount } = useContext(AppsRemainingContext);

	const [buttonsLoading, setButtonsLoading] = useState({
		loading: false,
		type: '',
	});
	const [showRejectionForm, setShowRejectionForm] = useState(false);

	const approve = async () => {
		setButtonsLoading({ loading: true, type: 'approve' });
		try {
			const success = await approveDecision({
				decision: activeApp.metaData.actionName,
				categoryReason: activeApp.metaData.categoryReason,
				id: activeApp.metaData.affectedUserId,
				shouldAskToVerifyInstagram: activeApp.instagram === 'none',
			});
			if (!success) {
				setButtonsLoading({ loading: false, type: '' });
			} else {
				decrementCount();
				decrementMinionCount();
			}
		} catch (error) {
			console.log('ereer', error);
			setButtonsLoading({ loading: false, type: '' });
		}
	};

	const reject = async ({ rating, decision, notes, welcomeMessage, categoryReason }) => {
		setButtonsLoading({ loading: true, type: 'reject' });
		try {
			const success = await rejectDecision({
				application: activeApp,
				rating: rating || activeApp.metaData.rating,
				decision: decision || activeApp.metaData.actionName,
				notes,
				welcomeMessage,
				categoryReason,
			});
			if (!success) {
				setButtonsLoading({ loading: false, type: '' });
			} else {
				decrementCount();
				setShowRejectionForm(false);
			}
		} catch (error) {
			setButtonsLoading({ loading: false, type: '' });
			console.log(error);
		}
	};

	const getWelcomeMessage = () => {
		if (!metaData.welcomeMessage && !activeApp.welcomeMessage) {
			return <Text style={[{ color: 'red' }, styles.normal]}>No Message</Text>;
		}
		return <Text style={styles.normal}>{metaData.welcomeMessage ?? activeApp.welcomeMessage}</Text>;
	};

	return (
		<View style={styles.metadataContainer}>
			<View style={styles.actionsContainer}>
				<Text style={styles.name}>
					Reviewed by: <Text style={styles.normal}>{admins[metaData.userId] || 'Unknown'}</Text>
				</Text>
				<Text style={styles.name}>
					Alec-Scale Rating: <Text style={styles.normal}>{metaData.rating}</Text>
				</Text>
				<Text style={styles.name}>
					Decision: <Text style={styles.normal}>{capitalize(metaData.actionName)}</Text>
				</Text>
				{metaData.categoryReason && (
					<Text style={styles.name}>
						Decision Reason:{' '}
						<Text style={styles.normal}>
							{categoryReasonToHumanReadableMap[metaData.categoryReason]}
						</Text>
					</Text>
				)}
				<Text style={styles.name}>Welcome Message: {getWelcomeMessage()}</Text>
				{Boolean(metaData.notesForAdmin) && (
					<Text style={styles.name}>
						Notes: <Text style={styles.normal}>{metaData.notesForAdmin}</Text>
					</Text>
				)}
				<MainButton
					containerStyle={{ alignItems: 'flex-start' }}
					onPress={approve}
					title="Agree"
					buttonStyles={styles.button}
					loading={buttonsLoading.type === 'approve'}
					disabled={buttonsLoading.loading || showRejectionForm || !metaData.actionName}
				/>
				<MainButton
					containerStyle={{ alignItems: 'flex-start' }}
					onPress={() => {
						setShowRejectionForm(true);
					}}
					title="Disagree"
					buttonStyles={styles.button}
					loading={buttonsLoading.type === 'reject'}
					disabled={buttonsLoading.loading || showRejectionForm}
				/>
			</View>
			<RejectionForm
				onPress={reject}
				visible={showRejectionForm}
				activeApp={{ ...activeApp, ...metaData }}
				cancel={() => setShowRejectionForm(false)}
			/>
		</View>
	);
}

const styles = EStyleSheet.create({
	metadataContainer: {
		flex: 1,
		// justifyContent: 'center',
		alignItems: 'flex-start',
		// backgroundColor: 'red',
	},

	actionsContainer: {
		// alignItems: 'center',
		justifyContent: 'flex-start',
		marginBottom: 10,
	},

	button: {
		width: containerWidth * 0.25,
		marginVertical: 10,
		height: 50,
	},

	name: {
		fontFamily: '$bold',
		fontSize: 20,
		marginBottom: 10,
	},
	normal: {
		fontFamily: '$book',
		fontSize: 16,
	},
});

export default AdminActions;
