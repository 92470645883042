// const fonts = [
//   { ManropeRegular: require('./Manrope-Regular.ttf') },
//   { ManropeBold: require('./Manrope-Bold.ttf') },
//   { ManropeMedium: require('./Manrope-Medium.ttf') },
//   { ManropeExtraBold: require('./Manrope-ExtraBold.ttf') },
//   { ManropeSemiBold: require('./Manrope-SemiBold.ttf') },
// ];

const fonts = [
  { Circular: require('./Circular.otf') },
  { 'Circular-Black': require('./Circular-Black.otf') },
  { 'Circular-Bold': require('./Circular-Bold.otf') },
  { 'Circular-Book': require('./Circular-Book.otf') },
  { 'MostraNuova-Regular': require('./Mostra-Nuova.otf') },
];

export default fonts;
