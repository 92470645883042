import React, { useRef } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import {
	Animated,
	View,
	Text,
	TouchableOpacity,
	ActivityIndicator,
	Image,
	ViewStyle,
	StyleProp,
	ColorValue,
} from 'react-native';

function LetsGoButton({
	onPress,
	title,
	disabled,
	buttonStyles,
	disabledStyle,
	containerStyle,
	loading,
	transparent,
	disabledText,
	textStyles,
	testID,
	scaleYOnly = false,
	loadingColor = 'white',
}: {
	onPress: () => void;
	title: string;
	disabled?: boolean;
	showLegal?: boolean;
	buttonStyles?: StyleProp<ViewStyle>;
	disabledStyle?: StyleProp<ViewStyle>;
	containerStyle?: StyleProp<ViewStyle>;
	loading?: boolean;
	transparent?: boolean;
	disabledText?: StyleProp<ViewStyle>;
	textStyles?: StyleProp<ViewStyle>;
	testID?: string;
	scaleYOnly?: boolean;
	loadingColor?: ColorValue;
}) {
	const scale = useRef(new Animated.Value(1));
	const scaleProp = scaleYOnly ? 'scaleY' : 'scale';
	const handlePressIn = React.useCallback(() => {
		Animated.timing(scale.current, {
			toValue: 0.98,
			duration: 1,
			useNativeDriver: true,
		}).start();
	}, []);

	const handlePressOut = React.useCallback((e) => {
		Animated.timing(scale.current, {
			toValue: 1,
			duration: 1,
			useNativeDriver: true,
		}).start();
	}, []);

	return (
		<Animated.View
			style={[styles.container, containerStyle, { transform: [{ [scaleProp]: scale.current }] }]}
			testID={'LetsGoButton'}
		>
			<TouchableOpacity
				onPress={onPress}
				activeOpacity={1}
				style={[
					styles.button,
					disabled && !loading && [styles.disabled, disabledStyle],
					buttonStyles,
					transparent && styles.transparent,
				]}
				disabled={disabled}
				onPressIn={handlePressIn}
				onPressOut={handlePressOut}
				testID={testID}
			>
				{loading ? (
					<ActivityIndicator color={loadingColor} />
				) : (
					<Text
						style={[
							styles.text,
							textStyles,
							disabled && [styles.disabledText, disabledText],
							transparent && styles.transparentText,
						]}
					>
						{title}
					</Text>
				)}
			</TouchableOpacity>
		</Animated.View>
	);
}

const styles = EStyleSheet.create({
	container: {
		// flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		// height: '30%',
		// ...heightStyle(
		//   {
		//     paddingBottom: 100,
		//   },
		//   {
		//     paddingBottom: 50,
		//   },
		//   {
		//     paddingBottom: 50,
		//   },
		//   {
		//     paddingBottom: 50,
		//   }
		// ),
		// backgroundColor: 'red',
	},

	button: {
		// borderWidth: 1,
		// borderColor: '$textColor',
		backgroundColor: '$darkBlue',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '70%',
		borderRadius: 30,
		marginTop: 20,
		// marginBottom: 10,
		// paddingVertical: 15,
		shadowOpacity: 0.2,
		shadowRadius: 0,
		shadowColor: '$pinkDisabled',
		shadowOffset: { height: 1, width: 0 },
		height: 52,
	},

	text: {
		fontFamily: '$bold',
		fontSize: 14.7,
		color: '$white',
		// marginLeft: 5,
	},

	disabled: {
		// opacity: 01,
		backgroundColor: '$disabledGrey',
		borderColor: 'transparent',
	},
	disabledText: {
		color: '$disabledGreyText',
	},
	transparent: {
		backgroundColor: 'transparent',
		borderColor: 'transparent',
	},
	transparentText: {
		color: 'transparent',
	},
	iconContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		width: 22.7,
		height: 22.7,
		marginRight: 16,
		marginTop: 2,
	},
});

LetsGoButton.defaultProps = {
	title: 'Start',
	disabled: false,
	showLegal: false,
	containerStyles: {},
	loading: false,
};

export default LetsGoButton;
